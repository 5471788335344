<template>
  <v-tree-catalog
      v-bind="{
        title,
        tableCellsConfig: filteredTableCellsConfig || tableCellsConfig,
        metricFilters,
        page: pageName
      }"
  />
</template>

<script>
import treeCatalog from '@/services/components/treeCatalog/'
import filterWSByProject from '@/mixins/filterWSByProject.js'

export default {
  components: {
    'v-tree-catalog': treeCatalog
  },

  mixins: [filterWSByProject],

  data () {
    return {
      pageName: 'contentAnalysis',

      title: {
        primary: 'Data analytics',
        secondary: 'Content analysis'
      }
    }
  },

  computed: {
    tableCellsConfig() {
      return this.$store.getters['tableSettings/getTableCellsConfigContentAnalysis']
    },

    metricFilters () {
      let items = [
        'ws2',
        'top3',
        'top5',
        'top10',
        'p_traf',
        'sessions',
        'users',
        'new_users',
        'transactions',
        'conversions',
        'transaction_revenue',
        'count_questions',
        'number_of_answers',
        'number_of_comments',
        'rating',
        'count_positive_review',
        'count_negative_review',
        'length_most_helpful_review',
        'count_photos',
        'price',
        'count_review',
        'count_videos',
        'count_video_360',
        'merchant_is_marketplace'
      ]
      if (!this.shouldFilterWSMetrics)  {
        items.push('ws1')
        items.push('ws3')
      }
      return items
    },
  }
}
</script>

<style lang="scss" scoped>
</style>
